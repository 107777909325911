<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('billing.hubooBoxSelection') | title"></huboo-page-heading>
    <huboo-table
      @row-clicked="onSelected"
      @search="handleSearch"
      @filter="handleFilter"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      v-bind="options"
      clickable
      :server-items-length="serverItemsLength"
      :loading="loading"
    ></huboo-table>
  </huboo-page>
</template>

<script>
import { BILLING_TYPES } from '@/utilities/constants'
import { parentheses, title, upperCase } from '@/utilities/filters'
import { goTo } from '@/utilities/helpers/router'
import Vue from 'vue'
import { formatDate, handleSearch, orderBy } from '@/views/pages/billing/billingViewHelpers'

const { MULTI_ITEM, PICK_RATE } = BILLING_TYPES

export default {
  name: 'HubooBoxSelection',
  components: {},
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
  }),
  computed: {
    filters() {
      return null
      // TODO: Activate when filtering available via the API
      // return [
      //   {
      //     label: this.$t('billing.billingType'),
      //     items: [
      //       { text: this.$t('billing.multiItem'), value: MULTI_ITEM },
      //       { text: this.$t('billing.pickRate'), value: PICK_RATE },
      //     ],
      //     name: 'billingType',
      //   },
      // ]
    },
    headers() {
      return [
        {
          text:
            title(this.$t('billing.hubooBox')) + ' ' + parentheses(upperCase(this.$t('common.id'))),
          sortable: true,
          value: 'hubooBox',
        },
        { text: title(this.$t('billing.billingType')), sortable: false, value: 'billingType' }, // MI or PR
        { text: title(this.$t('billing.billingRegion')), sortable: false, value: 'billingRegion' },
        { text: title(this.$t('billing.invoiceName')), sortable: true, value: 'invoiceName' },
        { text: title(this.$t('billing.invoiceEmail')), sortable: true, value: 'invoiceEmail' },
        {
          text: title(this.$t('billing.invoicingPreference')),
          sortable: false,
          value: 'invoicingPreference',
        },
        { text: title(this.$t('common.enabled')), sortable: false, value: 'enabled' },
      ]
    },
    hubooBoxes() {
      return this.$store.getters['billing/getHubooBoxes']
    },
    items() {
      return this.hubooBoxes.data.map(hubooBox => {
        const f = { ...hubooBox } // Clone object to avoid mutating original
        if (f.billingType === MULTI_ITEM) f.billingType = 'Multi Item'
        if (f.billingType === PICK_RATE) f.billingType = 'Pick Rate'
        if (f.billingRegion === null) f.billingRegion = 'Not set'
        f.enabled = this.setEnabled(f.enabled)
        return f
      })
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: 'client-billing-setups',
      })
    },
    meta() {
      return this.hubooBoxes?.meta || { total: this.items.length }
    },
    orderBy() {
      return orderBy(this)
    },
    serverItemsLength() {
      return this.meta.total || 0
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
    $route: {
      handler(r) {
        if (r.name === 'HubooBoxSelection') this.fetch()
      },
      deep: true,
    },
  },
  created() {
    this.$store.dispatch('billing/fetchRegions')
  },
  methods: {
    fetch() {
      if (!this.loading) {
        const { options, orderBy, search } = this
        const { page } = options
        const params = {}
        if (page) params.page = page
        if (search) params.query = search
        if (orderBy) params.orderBy = orderBy
        params.itemsPerPage = this.options.itemsPerPage
        this.$store.dispatch('billing/fetchClientBillingSetups', { params })
      }
    },
    formatDate(d) {
      return formatDate(this, d)
    },
    goTo,
    handleFilter(e) {
      // TODO: implement filtering
      console.debug('filtering...', e)
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    handleUpdateOptions(e) {
      Vue.set(this, 'options', e)
    },
    onSelected({ hubooBox }) {
      const box = this.$store.getters['billing/getBoxById'](hubooBox)
      this.$store.commit('billing/setSelectedBox', box)
      this.goTo('/billing/huboo_boxes/' + hubooBox + '/summary', this.$route)
    },
    setEnabled(e) {
      if (e === true) return this.$t('common.yes')
      else if (e === false) return this.$t('common.no')
      return ''
    },
  },
}
</script>
